import React from 'react';
import logo1 from '../media/logo_blanco.png';
import logo from '../media/logo_eu.png';
import '../styles/App.css';
import {Link} from 'react-router-dom';

function LandingES() {
  return (
    <React.Fragment>
      <header className="app-header">
			<a
          className="app-link header"
          href="https://coopwood.eu/"
        >
					<div className="link-text header">Volver</div>
        </a>
        <div className="header-logo-box">
          <img className="header-logo" src={logo1} alt="Header CoopWood logo" />
        </div>
      </header>
      <main className="app-main">
        <div className="main-logo-box">
          <img src={logo} className="app-logo" alt="logo" />
        </div>
				<div className="description-box">
					<h1 className="description-title">Descripción de la herramienta para el cálculo de Montantes en entrenado ligero</h1>
					<div className="description-text-box">
						<p className="description-text">
							Herramienta para el cálculo de Montantes en entrenado ligero: La presente herramienta sirve para calcular los montantes de entramado ligero trabajando a flexocompresión según las directrices del CTE DB SE-M.
						</p>
						<p className="description-text">
							Esta herramienta  está financiada en un  65%, por el proyecto EFA260/16 - Cooperando con madera “COOPWOOD”, perteneciente al programa Interreg-POCTEFA. 
						</p> 
							<p className="description-text">
							Para utilizar esta herramienta, el usuario debe introducir una serie de valores en las casillas vacias. NOTA: La hoja de cálculo ha sido realizada por TKNIKA, en el ámbito del proyecto Interg POCTEFA  EFA26-16 COOPWOOD. El uso de la misma es responsabilidad exclusiva de quien lo realice, es decir, TKNIKA ni ningún socio del proyecto COOPWOOD  se hace responsable ni responderá de ninguna consecuencia, daño o perjuicio que pudiera derivarse de los resultados obtenidos con la herramienta, independientemente que su uso sea adecuado o no.
						</p>  
					</div>	
				</div>  
				<Link
          className="app-link btn"
          to='/calculo'
        >
					<div className="link-text">
						Cálculo montantes de entramado ligero
					</div>
        </Link>
      </main>
      <footer className="app-footer">
        <p className="footer-title">Copyright CoopWood 2018</p>
      </footer>
    </React.Fragment>
  );
}

export default LandingES;
