import React from 'react';
import Recaptcha from 'react-recaptcha';

class Captcha extends React.Component {

  render() {
    return(
      <React.Fragment>
        <Recaptcha
          isVerified={this.props.isVerified} 
          sitekey="6LcC1dcUAAAAAPAnjTL6UUcMYXYEn45gT6-PlHIk"
          render="explicit"
          size="normal"
          onloadCallback={this.props.recatpchaLoaded}
          verifyCallback={this.props.verifyCallback}
        />
      </React.Fragment>

    )
  } 
}

export default Captcha;