import React from 'react';
import PrivacyES from './PrivacyES';
import PrivacyFR from './PrivacyFR';
import Captcha from './Captcha';
import '../styles/App.css';
import '../styles/Modal.css';


class Modal extends React.Component {

  render() {
    return (
      <div className="modal-body">
        <div className="modal-box">
          <header className="modal-header">
            <h2 className="modal-title">Registro | Enregistrer</h2>
            <button id="modal-close-btn" className="modal-close-btn" onClick={this.props.closeModal}>X</button>
          </header>

          <main className="modal-main">
            <div className="email-box">
              <p className="email-text">
                Introduzca su correo electrónico para obtener los resultados del cálculo | Entrez votre email pour obtenir les résultats du calcul:
              </p>
              <input type="email" name="input-email" id="input-email" className="input-email" required onChange={this.props.getEmail} />
            </div>

            <div className="privacy-box">
              <p className="privacy-title-es"onClick={this.props.showPrivacyES} >Política de privacidad</p>
              <div className={`privacy-text-es ${this.props.es_privacy ? 'hidden' : ''}`} >
                <PrivacyES />
              </div>

              <p className="privacy-title-fr" onClick={this.props.showPrivacyFR}>Politique de privacité</p>
              <div className={`privacy-text-fr ${this.props.fr_privacy ? 'hidden' : ''}`} lang="fr" >
                <PrivacyFR />
              </div>
            </div>

            <div className="accept-box">
              <label>He leído la política de privacidad | J'ai lu la politique de privacité 
                <input className="accept-checkbox" type="checkbox" id="accept" value="accept" required onChange={this.props.checkBox} checked={this.props.isChecked} />
              </label>
              <div className="captcha-box">
                <Captcha
                  recatpchaLoaded={this.props.recatpchaLoaded}
                  verifyCallback={this.props.verifyCallback}
                  isVerified={this.props.isVerified}
                />
              </div>
            </div>
          </main>

          <footer className="footer submit-btn-box">
            <input id="submit" className="submit-btn" type="submit" value="Calcular | Calculer" onClick={this.props.handleFormSubmit} />
          </footer>
        </div>
      </div>
    );
  }
}

export default Modal;