import React from 'react';
import logo1 from '../media/logo_blanco.png';
import logo from '../media/logo_eu.png';
import '../styles/App.css';
import {Link} from 'react-router-dom';

function LandingFR() {
  return (
    <React.Fragment>
      <header className="app-header">
			<a
          className="app-link header"
          href="http://coopwood.eu/fr/"
        >
					<div className="link-text header">Retour</div>
        </a>
        <div className="header-logo-box">
          <img className="header-logo" src={logo1} alt="Header CoopWood logo" />
        </div>
      </header>
      <main className="app-main">
        <div className="main-logo-box">
          <img src={logo} className="app-logo" alt="logo" />
        </div>
				<div className="description-box">
					<h1 className="description-title">Description d'Outil de calcul des montants d'ossature bois</h1>
					<div className="description-text-box">
						<p className="description-text">
              Outil de calcul des montants d´ossature bois : Cet outil est utilisé pour calculer les montants d'ossature bois travaillant en flexo-compression selon les directives du règlement européen. Cet outil est financé à 65%, par le projet EFA260/16 - Coopération avec le bois "COOPWOOD", appartenant au programme Interreg-POCTEFA.
            </p>
						<p className="description-text">
              Pour utiliser cet outil, l'utilisateur doit entrer une série de valeurs dans les cases vides.
						</p> 
						<p className="description-text">
              NOTE : L´outil a été réalisée par TKNIKA, dans le cadre du projet Interg POCTEFA EFA26-16 COOPWOOD. L'utilisation de cet outil est sous la seule responsabilité de l'utilisateur, c'est-à-dire que TKNIKA et les partenaires du projet COOPWOOD ne sont pas responsables des conséquences, dommages ou préjudices pouvant découler des résultats obtenus avec l'outil, que son utilisation soit appropriée ou non.
						</p>  
					</div>	
				</div>  
				<Link
          className="app-link btn"
          to='/calculo'
        >
					<div className="link-text">
						Calcul des montants d'ossature bois
					</div>
        </Link>
      </main>
      <footer className="app-footer">
        <p className="footer-title">Copyright CoopWood 2018</p>
      </footer>
    </React.Fragment>
  );
}

export default LandingFR;
