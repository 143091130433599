import React from 'react';
import '../styles/App.css';
import '../styles/Modal.css';


class PrivacyES extends React.Component {

  render() {
    return (
      <React.Fragment>   
        <h3 className="privacy-text-title">DATOS DEL RESPONSABLE DEL TRATAMIENTO DE LOS DATOS</h3>
        
        <p className="privacy-text">Nombre entidad: <strong>TKNIKA</strong></p>

        <p className="privacy-text">Dirección: Zamalbide auzoa z/g, Barrio Zamalbide s/n, C.P. 20100, Errenteria (Gipuzkoa)</p>

        <p className="privacy-text">Correo electrónico: <a href="mailto:info@tknika.com">info@tknika.com</a></p>

        <ol className="privacy-list">
          <li className="privacy-list-items"><strong>Formulario de contacto web.</strong>
            <ul className="unorder-list">
              <li className="unorder-items" type="disc"><u>Finalidad</u>. Dar contestación a su solicitud de información realizada a través de nuestro formulario de contacto.</li>
              <li className="unorder-items" type="disc"><u>Legitimación</u>. La legitimación para el tratamiento de sus datos es el consentimiento expreso del interesado.</li>
              <li className="unorder-items" type="disc"><u>Destinatarios</u>. No se cederán datos a terceros, salvo obligación legal.</li>                  
            </ul>
          </li>

          <li className="privacy-list-items"><strong>Exclusivo para contactos profesionales.</strong>
            <ul className="unorder-list">
              <li className="unorder-items" type="disc"><u>Finalidad</u>. En <strong>TKNIKA</strong> tratamos sus datos personales con la finalidad de mantener la relación profesional que entre ambos tenemos.</li>
              <li className="unorder-items" type="disc"><u>Legitimación</u>. La legitimación para el tratamiento de sus datos es el interés legítimo.</li>
              <li className="unorder-items" type="disc"><u>Destinatarios</u>. No se cederán datos a terceros, salvo obligación legal.</li>                  
            </ul>
          </li>

          <li className="privacy-list-items"><strong>Envío de Newsletter</strong>
            <ul className="unorder-list">
              <li className="unorder-items" type="disc"><u>Finalidad</u>. Gestionar el envío de la Newsletter de <strong>TKNIKA</strong> a los usuarios que se suscriban a la misma.</li>
              <li className="unorder-items" type="disc"><u>Legitimación</u>. La legitimación para el tratamiento de los datos personales es el consentimiento expreso de los interesados que se suscriban en este servicio marcando la casilla destinada a tal efecto.</li>
              <li className="unorder-items" type="disc"><u>Destinatarios</u>. No se cederán datos a terceros salvo obligación legal.</li>                  
            </ul>
          </li>

          <li className="privacy-list-items"><strong>Candidaturas espontáneas.</strong>
            <ul className="unorder-list">
              <li className="unorder-items" type="disc"><u>Finalidad</u>. Hacerle partícipe de los procesos de selección de personal que se realicen por <strong>TKNIKA</strong>.</li>
              <li className="unorder-items" type="disc"><u>Legitimación</u>. La legitimación para el tratamiento de los datos de carácter personal se basa en el consentimiento expreso otorgado por el interesado que nos confiere en virtud de su candidatura espontánea.</li>
              <li className="unorder-items" type="disc"><u>Destinatarios</u>. No se cederán datos a terceros salvo obligación legal.</li>                  
            </ul>
          </li>

          <li className="privacy-list-items"><strong>Exclusivo para clientes.</strong>
            <ul className="unorder-list">
              <li className="unorder-items" type="disc"><u>Finalidad</u>.
                <ul className="unorder-list">
                  <li className="unorder-items-2">En <strong>TKNIKA</strong> tratamos sus datos personales con la finalidad de gestionar los servicios administrativos y de logística derivados de la relación contractual así como los compromisos derivados del contrato.</li>
                  <li className="unorder-items-2">En el supuesto de que nos haya otorgado su consentimiento, en  <strong>TKNIKA</strong> tratamos sus datos con la finalidad de ofrecerle nuestros productos y servicios, así como sobre información jornadas, charlas y formación del sector.</li>
                </ul>
              </li>
              <li className="unorder-items" type="disc"><u>Legitimación</u>. La legitimación para el tratamiento de sus datos es el consentimiento expreso del interesado, el cumplimiento de una obligación legal y la ejecución de un contrato.</li>
              <li className="unorder-items" type="disc"><u>Destinatarios</u>. No se cederán datos a terceros, salvo obligación legal.</li>                  
            </ul>
          </li>
        </ol>

        <h3 className="privacy-text-title">DERECHOS EN EL TRATAMIENTO DE DATOS</h3>
        <p className="privacy-text">Para todos los tratamientos de datos presentes en esta política de privacidad, podrá ejercitar los derechos de acceso, rectificación, supresión, oposición, así como otros derechos desarrollados en información adicional.</p>

        <h3 className="privacy-text-title">INFORMACIÓN ADICIONAL SOBRE PROTECCIÓN DE DATOS</h3>
        <p className="privacy-text">Los datos que <strong>TKNIKA</strong> pueda recopilar por el uso, navegación de la página web, a través de nuestros formularios, vía email o con motivo de la relación contractual que le une con <strong>TKNIKA</strong> son datos de carácter personal. A la hora de cumplimentar cada uno de los formularios presentes en nuestra página web existirán una serie de datos obligatorios, cuyos campos se encuentran marcados con el símbolo *. En el caso de no rellenar tales campos, no se podrán llevar a cabo las finalidades descritas de cada uno de los formularios.  En algunas ocasiones, le informamos de que por el simple uso y navegación en nuestra Página web, <strong>TKNIKA</strong> almacenará datos relativos a:</p>
          <ul className="unorder-list">
            <li>Dirección IP</li>
            <li>Versión del navegador</li>
            <li>Sistema operativo</li>
            <li>Duración de la visita o navegación por la Página web</li>
          </ul>
          <p className="privacy-text"><strong>TKNIKA</strong> se reserva el derecho a modificar o adaptar la presente Política de Privacidad en todo momento. Por lo tanto, le recomendamos que revise la misma cada vez que acceda a la Página Web.</p>
          <h4 className="privacy-text-title">¿Con qué finalidad tratamos sus datos personales?</h4>
          <p className="privacy-text">La finalidad del tratamiento de los datos recabados mediante los formularios disponibles en nuestra página web, vía email o en virtud de la relación contractual que le une con <strong>TKNIKA</strong>, será la descrita, en cada caso, en la información básica sobre protección de datos.</p>
          <h4 className="privacy-text-title">¿Durante cuánto tiempo vamos a conservar sus datos personales?</h4>
          <p className="privacy-text">En <strong>TKNIKA</strong> vamos a conservar sus datos de carácter personal durante los plazos establecidos en el recuadro que aparece a continuación:</p>                 
          <ul className="unorder-list">
            <li><strong>Formulario de contacto web:</strong> Sus datos de carácter personal serán tratados durante el tiempo estrictamente necesario para atender a su solicitud.</li>
            <li><strong>Envío de Newsletter:</strong> Sus datos de carácter personal serán conservados hasta que cancele su suscripción a la Newsletter de <strong>TKNIKA</strong>.</li>
            <li><strong>Candidatura espontánea:</strong> En virtud de la política de conservación de <strong>TKNIKA</strong>, sus datos de carácter personal serán conservados durante el plazo máximo de <strong>UN AÑO</strong> desde la recepción del currículum vitae.</li>
            <li><strong>Exclusivo para contactos profesionales.:</strong> Hasta que finalicen nuestras relaciones profesionales o decida cancelar u oponerse al tratamiento de sus datos de contacto.</li>
            <li><strong>Exclusivo para clientes:</strong> Sus  datos  de  carácter personal serán tratados hasta  que  finalice la relación contractual. Una vez finalizada la misma, y a efectos mercantiles, sus datos serán conservados por un período de 6 años en cumplimiento  de  lo  indicado  en  el  Código  de  comercio. A  efectos fiscales, sus datos serán conservados por un periodo de 4 años en cumplimiento de lo indicado en la Ley General Tributaria.</li>
          </ul>
          <p className="privacy-text">Sus datos de carácter personal utilizados para los envíos acerca de nuestros productos y servicios, así como sobre información jornadas, charlas y formación del sector, serán conservados hasta que revoque el consentimiento otorgado al efecto.</p>
        <h4 className="privacy-text-title">¿Cuál es la legitimación para el tratamiento de sus datos?</h4>
        <p className="privacy-text">La legitimación para el tratamiento de los datos que alberga la presente política de privacidad es:</p>
        <ul className="unorder-list">
          <li><strong>Ejecución de un contrato de prestación de servicios.</strong></li>
          <li><strong>Consentimiento explícito del interesado en los siguientes tratamientos de datos:</strong> 
            <ul className="unorder-list">
              <li>Tratamiento de los datos en los formularios de contacto web.</li>
              <li>Tratamiento de los datos facilitados en la suscripción a la Newsletter de <strong>TKNIKA</strong>.</li>
              <li>Tratamiento de los datos facilitados en virtud de su candidatura espontánea.</li>
              <li>Tratamiento de  los  datos de  clientes que aparecen reflejados en  las  facturas y contratos.</li>
              <li>El envío de información acerca de nuestros productos y servicios, así como sobre información jornadas, charlas y formación del sector, serán conservados hasta que revoque el consentimiento otorgado al efecto.</li>
            </ul>
          </li>
          <li><strong>Cumplimiento de una obligación legal</strong>:
            <ul className="unorder-list"> 
              <li>Los plazos de conservación de sus datos una vez finalizada la relación contractual se basa en el cumplimiento de lo establecido en el Código de Comercio.</li>
              <li>Comunicación de sus datos cuando así lo solicite la Inspección de Hacienda está amparada por la Ley 58/2003, de 17 de Diciembre, General Tributaria.</li>
            </ul>
          </li>
          <li><strong><u>Interés legítimo perseguido por el responsable del tratamiento.</u></strong> 
            <ul className="unorder-list">
              <li>El tratamiento de los datos de contactos profesionales.</li>
            </ul>
          </li>
        </ul>

        <h4 className="privacy-text-title">¿A qué destinatarios se comunicarán sus datos?</h4>
        <p className="privacy-text">Tal y como se ha indicado en la información básica no se cederán datos a terceros salvo obligación legal. En aquellos casos en que sea necesario, los datos de clientes podrán ser cedidos a los siguientes organismos, en cumplimiento una obligación legal:</p>
        <ul className="unorder-list">
          <li>Se podrán ceder a la Agencia Tributaria Española aquellos datos necesarios para el cumplimiento de la obligación legal.</li>
          <li>A las entidades bancarias que corresponda, para estar al corriente de pagos.</li>
          <li>En el supuesto de que nos sea solicitado, sus datos serán cedidos a la Inspección de Trabajo.</li>
        </ul>

        <h4 className="privacy-text-title">¿Cuáles son sus derechos en relación con el tratamiento de datos?</h4>
        <p className="privacy-text">Usted como titular de datos tiene derecho a obtener confirmación sobre la existencia de un tratamiento de sus datos, a acceder a sus datos personales, solicitar la rectificación de los datos que sean inexactos o, en su caso, solicitar la supresión, cuando entre otros motivos, los datos ya no sean necesarios para los fines para los que fueron recogidos o usted como interesado retire el consentimiento otorgado. <strong>TKNIKA</strong> tratará y conservará sus datos de acuerdo con la normativa vigente, sin perjuicio de que como interesado pueda solicitar en todo caso, la limitación del tratamiento de sus datos. En ciertos supuestos podrá ejercitar su derecho a la portabilidad de los datos, que serán entregados en un formato estructurado, de uso común o lectura mecánica a usted o al nuevo responsable de tratamiento que designe. Tendrá derecho a revocar en cualquier momento el consentimiento para cualquiera de los tratamientos para los que lo ha otorgado. Para ejercitar sus derechos comuníquese con nosotros a través de la dirección de correo electrónico <a href="mailto:info@tknika.com">info@tknika.com</a>. Tendrá derecho a presentar una reclamación ante la Agencia Española de Protección de Datos en el supuesto de que considere que no se ha atendido convenientemente el ejercicio de sus derechos. El plazo máximo para resolver será el de un mes a contar desde la recepción de su  olicitud. En el caso de producirse alguna modificación de sus datos, le agradecemos nos lo comunique debidamente por escrito con la finalidad de mantener sus datos actualizados.</p>

        <p className="privacy-text">© Copyright 2019 TKNIKA - Todos los derechos reservados.</p>
    </React.Fragment>
    );
  }
}

export default PrivacyES;