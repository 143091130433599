import React from 'react';
import {Link} from 'react-router-dom';
import logo1 from '../media/logo_blanco.png';
import '../styles/App.css';
import '../styles/Form.css';
import axios from 'axios';
import PopUp from '../components/PopUp';
import Modal from '../components/Modal';

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false,
      isVerified: false,
      showPopup: false,
      showModal: false,
      hidden: true,
      p_hidden: true,
      fr_privacy: true,
      es_privacy: true,
 	    insertData: {},
      detail: {}, 
      results: {}
    }

    this.showResults = this.showResults.bind(this);
    this.showParams = this.showParams.bind(this);
    this.getInputValue = this.getInputValue.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.showPrivacyES = this.showPrivacyES.bind(this);
    this.showPrivacyFR = this.showPrivacyFR.bind(this);
    this.recatpchaLoaded = this.recatpchaLoaded.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.checkBox = this.checkBox.bind(this);
    this.getEmail = this.getEmail.bind(this);
    this.printPDF = this.printPDF.bind(this);
    this.resetInputs = this.resetInputs.bind(this);
  }

  checkBox() {
    if (this.state.isChecked === false) {
      this.setState({
        isChecked: true
      })
    } else {
      this.setState({
        isChecked: false
      })
    }
  }

  showResults(e) {
    this.setState(
      {hidden: false}
    )
  }   
    
  resetInputs () {
    window.location.reload(false);
  }

  showParams(e) {
    if(this.state.p_hidden) {
      this.setState(
        {p_hidden: false}
      )
    } else {
      this.setState(
        {p_hidden: true}
      )     
    }     
  }

  showPrivacyES(e) {
    if(this.state.es_privacy) {
      this.setState(
        {es_privacy: false}
      )
    } else {
      this.setState(
        {es_privacy: true}
      )     
    }     
  }

  showPrivacyFR(e) {
    if(this.state.fr_privacy) {
      this.setState(
        {fr_privacy: false}
      )
    } else {
      this.setState(
        {fr_privacy: true}
      )     
    }     
  }  

  getInputValue(e) {
    e.preventDefault();
    const targetID = e.currentTarget.id;
    const targetValue = parseFloat(e.currentTarget.value);
    this.setState(prevState => ({
	      insertData: {
          ...prevState.insertData,
		      [targetID]: targetValue
        }
    }))
  }	  

  getEmail(e) {
    const emailValue = e.currentTarget.value;
    const today = new Date();
    const todayDate = today.getFullYear() + '-' + ("0" + (today.getMonth() + 1)).slice(-2) + '-' + ("0" + (today.getDate())).slice(-2);
    const todayTime = ("0" + (today.getHours())).slice(-2) + ':' + ("0" + (today.getMinutes())).slice(-2) + ':' + ("0" + (today.getSeconds() + 1)).slice(-2);
    this.setState(prevState => {
      return {
	      detail: {
          ...prevState.detail,
          email: emailValue,
          hora: todayTime,
          fecha: todayDate 
        }
      }
    })
  }

  checkEmail(email) {
    // eslint-disable-next-line no-useless-escape
    const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (regex.test(email)) {
      return true;
    } else {
        alert('Email incorrecto');
      return false;
    }
  }

  handleFormSubmit(e) {
    e.preventDefault();
    if (this.checkEmail(this.state.detail.email) && this.state.isChecked && this.state.isVerified){

      axios({
        method: 'post',
        url: 'https://montantes.coopwoodapps.net/services/daoform.php', 
        data: {
          insertData: this.state.insertData,
          detail: this.state.detail
        } 
      }) 
      .then(response => this.setState(
        {results: response.data}
      ),this.showResults(e))
      .then(this.setState({
        showModal: false,
        isVerified: false,
        isChecked: false,
        es_privacy: true,
        fr_privacy: true
      }))
    } else {
      if ((!this.state.isChecked && !this.state.isVerified)) {
        alert('Por favor, marque las casillas de verificación');
      } else if(this.state.isChecked && !this.state.isVerified) {
        alert('Por favor, valide el reCAPTCHA');
      }  else if(!this.state.isChecked && this.state.isVerified) {
        alert('Por favor, marque la casila de "He leído la política de privacidad"');
      }          
    }
  } 

  togglePopup() {
    this.setState({
      showPopup: !this.state.showPopup
    });
  }

  toggleModal(e) {
    const checkInput = (array) => !isNaN(array) && isFinite(array) && array !== null && array !== '';
    const array = Object.values(this.state.insertData);
    if(Object.keys(this.state.insertData).length === 19 && array.every(checkInput)) {
      e.preventDefault();
      this.setState({
        showModal: !this.state.showModal,
      })
    } else {
      e.preventDefault();
      alert('Por favor, introduzca correctamente todos los datos solicitados.')
    }
  }

  closeModal(e) {
    e.preventDefault();
    this.setState({
      showModal: !this.state.showModal,
      isChecked: false,
      es_privacy: true,
      fr_privacy: true,
      detail: {}
    })
  }

  printPDF() {
    axios({
      method: 'post',
      url: 'https://montantes.coopwoodapps.net/services/print_report.php', 
      data: {
        insertData: this.state.insertData,
        detail: this.state.detail
      },
      responseType: 'arraybuffer',
      headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/pdf'
      } 
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Informe_montantes_${this.state.detail.fecha}.pdf`);
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => console.log(error));
  }

  recatpchaLoaded() {
    console.log('captcha loaded');
  } 
  
  verifyCallback(response) {
    if (response) {
      this.setState({
        isVerified: true
      })
    } else {
      alert('Por favor, completa el captcha');
    }
  }

  render () {
    return (
      <div className="form-box">
        <header className="app-header">
          <div className="btn-back">
            <Link to="/"
              className="app-link header"
            >
              <div className="link-text header">Volver</div>
            </Link>
            <Link to="/fr"
              className="app-link header"
            >
              <div className="link-text header">Retour</div>
            </Link>
          </div>
          <div className="header-logo-box">
            <img className="header-logo" src={logo1} alt="Header CoopWood logo" />
          </div>
        </header>

        <h1 className="form-title">CÁLCULO DE MONTANTES EN ENTRENADO LIGERO | CALCUL DES MONTANTS D'OSSATURE BOIS</h1>

        <form className="form" id="form" action="#">
          <fieldset className="form-fieldset">
            <legend className="fieldset-legend">1. DATOS GEOMÉTRICOS | DONNÉES GÉOMÉTRIQUES</legend>
              <div className="fieldset-box">

                <div className="input-box">
                  <label htmlFor="alt_mont" className="form-label">Altura del montante | Hauteur du montant (mm)</label>
                  <input type="number" step="0.01" className="form-input" id="alt_mont" name="alt_mont" onChange={this.getInputValue}  />  
                </div>

                <div className="input-box">
                  <label htmlFor="ancho" className="form-label">Ancho | Epaisseur (b) (mm)</label>
                  <input type="number" step="0.01" className="form-input" id="ancho" name="ancho" onChange={this.getInputValue}  />  
                </div> 

                <div className="input-box">
                  <label htmlFor="canto" className="form-label">Canto | Hauteur (h) (mm)</label>
                  <input type="number" step="0.01" className="form-input" id="canto" name="canto" onChange={this.getInputValue}  />  
                </div>

                <div className="input-box">
                  <label htmlFor="sep_mont" className="form-label">Separación entre montantes | Ecartement entre montants (mm)</label>
                  <input type="number" step="0.01" className="form-input" id="sep_mont" name="sep_mont" onChange={this.getInputValue}  />  
                </div>

                <div className="input-box">
                  <label htmlFor="sep_fija" className="form-label">Separación entre elementos de fijación | Ecartement entre éléments de fixation (mm)</label>
                  <input type="number" step="0.01" className="form-input" id="sep_fija" name="sep_fija" onChange={this.getInputValue}  />  
                </div>
              </div>
          </fieldset>

          <fieldset className="form-fieldset">
            <legend className="fieldset-legend">2. CLASE RESISTENTE | CLASE DE RÉSISTANCE</legend>
              <div className="fieldset-box">
                  <div className="input-box">
                    <label htmlFor="resistencia" className="form-label">Clase resistente | Clase de résistance (CTE DE SE-M) (UNE-EN_14080 (2013))</label>
                    <select id="resistencia" className="form-input" onChange={this.getInputValue}  >
                      <option value=""></option>
                      <option value="1">C14</option>
                      <option value="2">C16</option>
                      <option value="3">C18</option>
                      <option value="4">C20</option>
                      <option value="5">C22</option>
                      <option value="6">C24</option>
                      <option value="7">C27</option>
                      <option value="8">C30</option>
                      <option value="9">D18</option>
                      <option value="10">D24</option>
                      <option value="11">D30</option>
                      <option value="12">D30CAS</option>
                      <option value="13">D35</option>
                      <option value="14">D40</option>
                      <option value="15">D40EUC</option>
                      <option value="16">GL24h</option>
                      <option value="17">GL28h</option>
                      <option value="18">GL30h</option>
                      <option value="19">GL32h</option>
                    </select>  
                  </div>
                </div>
          </fieldset>

          <fieldset className="form-fieldset">
            <legend className="fieldset-legend">3. ACCIONES | ACTIONS</legend>
            <div className="fieldset-box action">
                <div className="action-box">
                  <div className="input-box">
                    <label htmlFor="planta" className="form-label">Planta de cálculo | Surface de calcul</label>
                    <select id="planta" className="form-input" onChange={this.getInputValue}  >
                        <option value=""></option>
                        <option value="1">PB</option>
                        <option value="2">P1</option>
                        <option value="3">P2</option>
                        <option value="4">P3</option>
                      </select> 
                  </div>

                <div className="input-box">
                  <label htmlFor="n_forj" className="form-label">Nº de forjados de entreplanta por encima | Nombre de mezzanines au-dessus du plancher</label>
                  <input type="number" step="0.01" className="form-input" id="n_forj" name="n_forj" onChange={this.getInputValue}  />  
                </div>

                <div className="input-box">
                  <label htmlFor="long_forj" className="form-label">Long. forjado/cubierta soportada por el muro | Long. solivage/toiture en appui sur le mur (m)</label>
                  <input type="number" step="0.01" className="form-input" id="long_forj" name="long_forj" onChange={this.getInputValue}  />  
                </div>

                <div className="input-box">
                  <label htmlFor="alt_max_cub" className="form-label">Altura máxima cubierta | Hauteur maximale de toiture (m)</label>
                  <select id="alt_max_cub" className="form-input" onChange={this.getInputValue}  >
                      <option value=""></option>
                      <option value="1">1,5</option>
                      <option value="2">3</option>
                      <option value="3">4,5</option>
                      <option value="4">6</option>
                      <option value="5">7.5</option>
                      <option value="6">9</option>
                      <option value="7">10.5</option>
                      <option value="8">12</option>
                    </select> 
                </div>
              </div>

                <p className="form-subtitle">4. Carga permanente | Charge permanente</p>
                <div className="action-box">
                  <div className="input-box">                   
                    <label htmlFor="carg_perm_for" className="form-label">Carga permanente forjado | Charge permanente plancher (kN/m2)</label>
                    <input type="number" step="0.01" className="form-input" id="carg_perm_for" name="carg_perm_for" onChange={this.getInputValue}  />  
                  </div>

                  <div className="input-box">
                    <label htmlFor="carg_perm_cub" className="form-label">Carga permanente cubierta | Charge permanente en toiture (kN/m2)</label>
                    <input type="number" step="0.01" className="form-input" id="carg_perm_cub" name="carg_perm_cub" onChange={this.getInputValue}  />  
                  </div>

                  <div className="input-box">
                    <label htmlFor="carg_cerra" className="form-label">Carga cerramiento por planta | Charge des cloisons par étage (kN/m)</label>
                    <input type="number" step="0.01" className="form-input" id="carg_cerra" name="carg_cerra" onChange={this.getInputValue}  />  
                  </div>
                </div>
                <p className="form-subtitle">5. Sobrecarga de uso | Surcharge d’utilisation</p>
                
                <div className="action-box">
                  <div className="input-box">
                    <label htmlFor="scarg_uso_for" className="form-label">Sobrecarga de uso forjado | Surcharge d’utilisation plancher (kN/m2)</label>
                    <input type="number" step="0.01" className="form-input" id="scarg_uso_for" name="scarg_uso_for" onChange={this.getInputValue}  />  
                  </div>
                                  
                  <div className="input-box">
                    <label htmlFor="scarg_uso_cub" className="form-label">Sobrecarga de uso cubierta | Surcharge d’utilisation en toiture (kN/m2)</label>
                    <input type="number" step="0.01" className="form-input" id="scarg_uso_cub" name="scarg_uso_cub" onChange={this.getInputValue}  />  
                  </div>
                </div>

                <p className="form-subtitle">6. Carga de nieve | Charge de neige</p>

                <div className="input-box">
                  <label htmlFor="carg_nieve_cub" className="form-label">Carga de nieve cubierta | Charge de neige en toiture (kN/m2)</label>
                  <input type="number" step="0.01" className="form-input" id="carg_nieve_cub" name="carg_nieve_cub" onChange={this.getInputValue}  />  
                </div>

                <p className="form-subtitle">7. Carga de viento | Charge de vent</p>

                <div className="action-box">
                  <div className="input-box">
                    <label htmlFor="viento" className="form-label">Zona viento/presión dinámica | Zone dynamique vent/pression                             <span>   
                          <span className="map-link" onClick={this.togglePopup.bind(this)} >(mapa | carte)</span>
                          {this.state.showPopup ? 
                            <PopUp
                              closePopup={this.togglePopup.bind(this)}
                            />
                            : null
                          }
                      </span>
                    </label>
                    <select id="viento" className="form-input" onChange={this.getInputValue}  >
                        <option value=""></option>
                        <option value="1">A</option>
                        <option value="2">B</option>
                        <option value="3">C</option>
                      </select> 
                  </div>

                  <div className="input-box">
                    <label htmlFor="emplazamiento" className="form-label">Tipo de emplazamiento | Type d'emplacement</label>
                    <select id="emplazamiento" className="form-input" onChange={this.getInputValue}  >
                        <option value=""></option>
                        <option value="1">Borde mar | Bord mer</option>
                        <option value="2">Rural llano | Rural plat</option>
                        <option value="3">Rural obstáculos | Rural obstacles</option>
                        <option value="4">Urb/indust/forest.</option>
                        <option value="5">Edificios altos | Grands immeubles</option>
                      </select> 
                  </div>

                  <div className="input-box">
                    <label htmlFor="coef_pmax_cub" className="form-label">Coeficiente de presión max. en cubierta | Coefficient de pression max. en toiture</label>
                    <input type="number" step="0.01" className="form-input" id="coef_pmax_cub" name="coef_pmax_cub" onChange={this.getInputValue}  />  
                  </div>
                </div>
              </div>
          </fieldset>
          <div className="submit-btn-box">
            <input id="submit" className="submit-btn" type="submit" value="Calcular | Calculer" onClick={this.toggleModal} />
            {this.state.showModal ? 
                          <Modal
                            handleFormSubmit={this.handleFormSubmit}
                            es_privacy={this.state.es_privacy}
                            fr_privacy={this.state.fr_privacy}
                            showPrivacyES={this.showPrivacyES}
                            showPrivacyFR={this.showPrivacyFR}
                            closeModal={this.closeModal}
                            recatpchaLoaded={this.recatpchaLoaded}
                            verifyCallback={this.verifyCallback}
                            isVerified={this.state.isVerified}
                            isChecked={this.state.isChecked}
                            checkBox={this.checkBox}
                            getToday={this.getToday}
                            email={this.state.insertData.email}
                            hora={this.state.insertData.hora}
                            fecha={this.state.insertData.fecha}
                            getEmail={this.getEmail}
                          />
                          : null
                        }
          </div>
          
          
          <div className={`results_params ${this.state.hidden ? 'hidden' : ''}`}>
            <div className="show_results">
              <p className="fieldset-legend">RESULTADOS CÁLCULOS | RÉSULTATS DES CALCULS</p>
              <p className="form-subtitle">Índices de agotamiento | Taux d'épuisement</p>
              <div className="action-box">
                <div className="input-box">                   
                  <label htmlFor="flexion" className="form-label">Flexion | Flexion</label>
                  <input type="text" 
                    className={`form-input ${this.state.results.flexion > 1 ? 'no-cumple' : 'cumple'}`} 
                    id="flexion" name="flexion" readOnly
                    value={this.state.results.flexion > 1 ? this.state.results.flexion + ' - No cumple | Inconformité': this.state.results.flexion + ' - Cumple | Conformité'} />  
                </div>
                <div className="input-box">                   
                  <label htmlFor="cortante" className="form-label">Cortante | Cisaillement</label>
                  <input type="text" 
                    className={`form-input ${this.state.results.cortante > 1 ? 'no-cumple' : 'cumple'}`}
                    id="cortante" name="cortante" readOnly 
                    value={this.state.results.cortante > 1 ? this.state.results.cortante + ' - No cumple | Inconformité': this.state.results.cortante + ' - Cumple | Conformité'} />  
                </div>
                <div className="input-box">                   
                  <label htmlFor="compresion" className="form-label">Compresión | Compression</label>
                  <input type="text" 
                    className={`form-input ${this.state.results.compresion > 1 ? 'no-cumple' : 'cumple'}`}
                    id="compresion" name="compresion" readOnly 
                    value={this.state.results.compresion > 1 ? this.state.results.compresion + ' - No cumple | Inconformité': this.state.results.compresion + ' - Cumple | Conformité'} />  
                </div>
                <div className="input-box">                   
                  <label htmlFor="flexocomp" className="form-label">Flexocompresión | Flexocompression</label>
                  <input type="text" 
                    className={`form-input ${this.state.results.flexocomp > 1 ? 'no-cumple' : 'cumple'}`} 
                    id="flexocomp" name="flexocomp" readOnly 
                    value={this.state.results.flexocomp > 1 ? this.state.results.flexocomp + ' - No cumple | Inconformité': this.state.results.flexocomp + ' - Cumple | Conformité'} />  
                </div>
                <div className="input-box">                   
                  <label htmlFor="flecha_integ" className="form-label">Flecha de integridad | Flèche d'intégrité L/300</label>
                  <input type="text" 
                    className={`form-input ${this.state.results.flecha_integ > 1 ? 'no-cumple' : 'cumple'}`} 
                    id="flecha_integ" name="flecha_integ" readOnly 
                    value={this.state.results.flecha_integ > 1 ? this.state.results.flecha_integ + ' - No cumple | Inconformité': this.state.results.flecha_integ + ' - Cumple | Conformité'} />  
                </div>
            </div>
            </div>

            <div className="params_box" >
              <p className="fieldset-legend params-title" onClick={this.showParams}>PARÁMETROS DE CÁLCULO | PARAMÈTRES DE CALCUL</p>
              <div id="params" className={`show_params ${this.state.p_hidden ? 'hidden' : ''}`}>
                <p className="form-subtitle props-section">PROPIEDADES DE LA SECCIÓN | PROPRIÉTÉS DE SECTION</p>
                <div className="action-box">
                  <div className="input-box">                   
                    <label htmlFor="A" className="form-label">A (mm2)</label>
                    <input type="text" className="form-input" id="A" name="A" readOnly="readOnly" value={this.state.results.a} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="Wy" className="form-label">Wy (mm2)</label>
                    <input type="text" className="form-input" id="Wy" name="Wy" readOnly="readOnly" value={this.state.results.wy} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="Iy" className="form-label">Iy (mm2)</label>
                    <input type="text" className="form-input" id="Iy" name="Iy" readOnly="readOnly" value={this.state.results.iy} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="Iz" className="form-label">Iz (mm2)</label>
                    <input type="text" className="form-input" id="Iz" name="Iz" readOnly="readOnly" value={this.state.results.iz} />  
                  </div>
                </div>

                <p className="form-subtitle strong-axis">COEFICIENTE DE PANDEO EJE FUERTE | COEFFICIENT DE FLAMBAGE AXE FORT</p>
                <div className="action-box">
                  <div className="input-box">                   
                    <label htmlFor="ef_rad_giro" className="form-label">Radio de giro i | Rayon de virage i (mm)</label>
                    <input type="text" className="form-input" id="ef_rad_giro" name="ef_rad_giro" readOnly="readOnly" value={this.state.results.ef_rad_giro} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="ef_esb_mec" className="form-label">Esbeltez mecánica | Elancement mécanique</label>
                    <input type="text" className="form-input" id="ef_esb_mec" name="ef_esb_mec" readOnly="readOnly" value={this.state.results.ef_esb_mec} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="ef_esb_euler" className="form-label">Esbeltez de Euler | Elancement de Euler</label>
                    <input type="text" className="form-input" id="ef_esb_euler" name="ef_esb_euler" readOnly="readOnly" value={this.state.results.ef_esb_euler} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="ef_esb_rel" className="form-label">Esbeltez relativa | Elancement relative</label>
                    <input type="text" className="form-input" id="ef_esb_rel" name="ef_esb_rel" readOnly="readOnly" value={this.state.results.ef_esb_rel} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="ef-βc" className="form-label">βc</label>
                    <input type="text" className="form-input" id="ef-βc" name="ef-βc" readOnly="readOnly" value={this.state.results.bc} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="k" className="form-label">k</label>
                    <input type="text" className="form-input" id="k" name="k" readOnly="readOnly" value={this.state.results.ef_k} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="ef-χc" className="form-label">χc</label>
                    <input type="text" className="form-input" id="ef-χc" name="ef-χc" readOnly="readOnly" value={this.state.results.ef_xc} />  
                  </div>
                </div>

                <p className="form-subtitle weak-axis">COEFICIENTE DE PANDEO EJE DÉBIL | COEFFICIENT DE FLAMBAGE AXE FRAGILE</p>
                <div className="action-box">
                  <div className="input-box">                   
                    <label htmlFor="ed_rad_giro" className="form-label">Radio de giro i | Rayon de virage i (mm)</label>
                    <input type="text" className="form-input" id="ed_rad_giro" name="ed_rad_giro" readOnly="readOnly" value={this.state.results.ed_rad_giro} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="ed_esb_mec" className="form-label">Esbeltez mecánica | Elancement mécanique</label>
                    <input type="text" className="form-input" id="ed_esb_mec" name="ed_esb_mec" readOnly="readOnly" value={this.state.results.ed_esb_mec} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="ed_esb_euler" className="form-label">Esbeltez de Euler | Elancement de Euler</label>
                    <input type="text" className="form-input" id="ed_esb_euler" name="ed_esb_euler" readOnly="readOnly" value={this.state.results.ed_esb_euler} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="ed_esb_rel" className="form-label">Esbeltez relativa | Elancement relative</label>
                    <input type="text" className="form-input" id="ed_esb_rel" name="ed_esb_rel" readOnly="readOnly" value={this.state.results.ed_esb_rel} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="ed-βc" className="form-label">βc</label>
                    <input type="text" className="form-input" id="ed-βc" name="ed-βc" readOnly="readOnly" value={this.state.results.bc} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="ed_k" className="form-label">k</label>
                    <input type="text" className="form-input" id="ed_k" name="ed_k" readOnly="readOnly" value={this.state.results.ed_k} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="ed-χc" className="form-label">χc</label>
                    <input type="text" className="form-input" id="ed-χc" name="ed-χc" readOnly="readOnly" value={this.state.results.ed_xc} />  
                  </div>
                </div>

                <p className="form-subtitle">ACCIONES VERTICALES | ACTIONS VERTICALES</p>
                <div className="action-box">
                  <div className="input-box">                   
                    <label htmlFor="carg_perm_mayor" className="form-label">Carga permanente mayorada | Charge permanente accrue (kN)</label>
                    <input type="text" className="form-input" id="carg_perm_mayor" name="carg_perm_mayor" readOnly="readOnly" value={this.state.results.carg_perm_mayor} />  
                  </div>
                  <div className="input-box">               
                    <label htmlFor="coef_exp_cub" className="form-label">Coeficiente de exposición en cubierta | Coefficient d’exposition toiture</label>
                    <input type="text" className="form-input" id="coef_exp_cub" name="coef_exp_cub" readOnly="readOnly" value={this.state.results.coef_exp_cub} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="pdin_viento" className="form-label">Presión dinámica del viento | Pression dynamique du vent</label>
                    <input type="text" className="form-input" id="pdin_viento" name="pdin_viento" readOnly="readOnly" value={this.state.results.pdin_viento} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="carg_viento_cub" className="form-label">Carga de viento en cubierta | Charge du vent en toiture (kN/m2)</label>
                    <input type="text" className="form-input" id="carg_viento_cub" name="carg_viento_cub" readOnly="readOnly" value={this.state.results.carg_viento_cub} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="carg_var_mayor" className="form-label">Carga variable mayorada | Charge variable accrue (kN)</label>
                    <input type="text" className="form-input" id="carg_var_mayor" name="carg_var_mayor" readOnly="readOnly" value={this.state.results.carg_var_mayor} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="carg_vert_mayor" className="form-label">Carga vertical mayorada | Charge variable accrue (kN)</label>
                    <input type="text" className="form-input" id="carg_vert_mayor" name="carg_vert_mayor" readOnly="readOnly" value={this.state.results.carg_vert_mayor} />  
                  </div>
                </div>

                <p className="form-subtitle">ACCIONES HORIZONTALES | ACTIONS HORIZONTALES</p>
                <div className="action-box">
                  <div className="input-box">                   
                    <label htmlFor="alt_consid" className="form-label">Altura considerada | Hauteur estimé</label>
                    <input type="text" className="form-input" id="alt_consid" name="alt_consid" readOnly="readOnly" value={this.state.results.alt_consid} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="coef_expo_pared" className="form-label">Coeficiente de exposición paredes | Coefficient d’exposition des murs</label>
                    <input type="text" className="form-input" id="coef_expo_pared" name="coef_expo_pared" readOnly="readOnly" value={this.state.results.coef_exp_pared} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="max_pres" className="form-label">Máx. presión/succión paredes | Pression max./aspiration parois (Tab. D.3 CTE DB SE-AE)</label>
                    <input type="text" className="form-input" id="max_pres" name="max_pres" readOnly="readOnly" value={this.state.results.max_pres} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="carg_viento_pared_mayor" className="form-label">Carga de viento en paredes mayorada | Charge du vent accrue dans les parois (kN/m)</label>
                    <input type="text" className="form-input" id="carg_viento_pared_mayor" name="carg_viento_pared_mayor" readOnly="readOnly" value={this.state.results.carg_viento_pared_mayor} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="mom_max_vano" className="form-label">Momento máximo en vano | Moment maximale à vide (kN/m)</label>
                    <input type="text" className="form-input" id="mom_max_vano" name="mom_max_vano" readOnly="readOnly" value={this.state.results.moment_max_vano} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="cort_max_apo" className="form-label">Cortante máximo en apoyo | Cisaillement maximum en appui (kN)</label>
                    <input type="text" className="form-input" id="cort_max_apo" name="cort_max_apo" readOnly="readOnly" value={this.state.results.cortant_max_apoyo} />  
                  </div>
                </div>

                <p className="form-subtitle tension">TENSIONES | TENSION</p>
                <div className="action-box">
                  <div className="input-box">                   
                    <label htmlFor="σm" className="form-label">σm,d (N/mm2)</label>
                    <input type="text" className="form-input" id="σm" name="σm" readOnly="readOnly" value={this.state.results.omd} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="τd" className="form-label">τd (N/mm2)</label>
                    <input type="text" className="form-input" id="τd" name="τd" readOnly="readOnly" value={this.state.results.td} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="σc" className="form-label">σc,0,d (N/mm2)</label>
                    <input type="text" className="form-input" id="σc" name="σc" readOnly="readOnly" value={this.state.results.oc0d} />  
                  </div>
                </div>

                <p className="form-subtitle props-mec">PROPIEDADES MECÁNICAS | CARACTÉRISTIQUES MÉCANIQUES</p>
                <div className="action-box">
                  <div className="input-box">                   
                    <label htmlFor="fmk" className="form-label">fm,k (N/mm2)</label>
                    <input type="text" className="form-input" id="fmk" name="fmk" readOnly="readOnly" value={this.state.results.fmk} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="ft0k" className="form-label">ft,0,k (N/mm2)</label>
                    <input type="text" className="form-input" id="ft0k" name="ft0k" readOnly="readOnly" value={this.state.results.ft0k} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="ft90k" className="form-label">ft,90,k (N/mm2)</label>
                    <input type="text" className="form-input" id="ft90k" name="ft90k" readOnly="readOnly" value={this.state.results.ft90k} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="fc0k" className="form-label">fc,0,k (N/mm2)</label>
                    <input type="text" className="form-input" id="fc0k" name="fc0k" readOnly="readOnly" value={this.state.results.fc0k} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="fc90k" className="form-label">fc,90,k (N/mm2)</label>
                    <input type="text" className="form-input" id="fc90k" name="fc90k" readOnly="readOnly" value={this.state.results.fc90k} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="fvk" className="form-label">fv,k (N/mm2)</label>
                    <input type="text" className="form-input" id="fvk" name="fvk" readOnly="readOnly" value={this.state.results.fvk} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="em" className="form-label">Em (N/mm2)</label>
                    <input type="text" className="form-input" id="em" name="em" readOnly="readOnly" value={this.state.results.em} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="e0k" className="form-label">E0k (N/mm2)</label>
                    <input type="text" className="form-input" id="e0k" name="e0k" readOnly="readOnly" value={this.state.results.e0k} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="gm" className="form-label">Gm (N/mm2)</label>
                    <input type="text" className="form-input" id="gm" name="gm" readOnly="readOnly" value={this.state.results.gm} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="gok" className="form-label">Go,k (N/mm2)</label>
                    <input type="text" className="form-input" id="gok" name="gok" readOnly="readOnly" value={this.state.results.gok} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="ρm" className="form-label">ρm (kg/m3)</label>
                    <input type="text" className="form-input" id="ρm" name="ρm" readOnly="readOnly" value={this.state.results.pm} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="ρk" className="form-label">ρk (kg/m3)</label>
                    <input type="text" className="form-input" id="ρk" name="ρk" readOnly="readOnly" value={this.state.results.pk} />  
                  </div>
                </div>

                <p className="form-subtitle">COEFICIENTES | COEFFICIENT</p>
                <div className="action-box">
                  <div className="input-box">                   
                    <label htmlFor="carg_comp" className="form-label">Carga compartida | Charge repartie</label>
                    <input type="text" className="form-input" id="carg_comp" name="carg_comp" readOnly="readOnly" value={this.state.results.carg_compart} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="seguridad" className="form-label">Seguridad | Sécurité</label>
                    <input type="text" className="form-input" id="seguridad" name="seguridad" readOnly="readOnly" value={this.state.results.seguridad} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="Kmod" className="form-label">Kmod</label>
                    <input type="text" className="form-input" id="Kmod" name="Kmod" readOnly="readOnly" value={this.state.results.kmod} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="Kh" className="form-label">Kh</label>
                    <input type="text" className="form-input" id="Kh" name="Kh" readOnly="readOnly" value={this.state.results.kh} />  
                  </div>
                </div>

                <p className="form-subtitle">RESISTENCIAS DE CÁLCULO | RESISTANCES DE CALCUL</p>
                <div className="action-box">
                  <div className="input-box">                   
                    <label htmlFor="fmd" className="form-label">fm,d (N/mm2)</label>
                    <input type="text" className="form-input" id="fmd" name="fmd" readOnly="readOnly" value={this.state.results.fmd} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="fvd" className="form-label">fv,d (N/mm2)</label>
                    <input type="text" className="form-input" id="fvd" name="fvd" readOnly="readOnly" value={this.state.results.fvd} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="fc0d" className="form-label">fc,0,d (N/mm2)</label>
                    <input type="text" className="form-input" id="fc0d" name="fc0d" readOnly="readOnly" value={this.state.results.fc0d} />  
                  </div>
                </div>

                <p className="form-subtitle">DEFORMACIONES | DEFORMATIONS</p>
                <div className="action-box">
                  <div className="input-box">                   
                    <label htmlFor="factor_cont_cort" className="form-label">Factor contribución cortante | Facteur de contribution au cisaillement</label>
                    <input type="text" className="form-input" id="factor_cont_cort" name="factor_cont_cort" readOnly="readOnly" value={this.state.results.fact_contr_cortant} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="lim_flec_int" className="form-label">Límite flecha integridad L/300 | Charge repartie (mm)</label>
                    <input type="text" className="form-input" id="lim_flec_int" name="lim_flec_int" readOnly="readOnly" value={this.state.results.lim_flecha_inte} />  
                  </div>
                  <div className="input-box">                   
                    <label htmlFor="flec_calc" className="form-label">Flecha calculada | Flèche calculée (mm)</label>
                    <input type="text" step="0.01" className="form-input" id="flec_calc" name="flec_calc" readOnly="readOnly" value={this.state.results.flecha_calcul} />  
                  </div>
                </div>
              </div>

              <div className="submit-btn-box">
                <input type="button" className="pdf-btn" value="Descargar PDF | Télécharger PDF" onClick={this.printPDF} />
                <input id="reset" className="reset-btn" type="reset" value="Nuevo cálculo | Nouveau calcul" onClick={this.resetInputs} />
              </div>
            </div>
          </div>
        </form>

        <footer className="app-footer">
          <p className="footer-title">Copyright CoopWood 2018</p>
        </footer>

      </div>
    );   
  }
}

export default Form;