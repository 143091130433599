import React from 'react';
import './styles/App.css';
import {Route, Switch} from 'react-router-dom';
import LandingES from './components/LandingES';
import LandingFR from './components/LandingFR';
import Form from './components/Form';

function App() {
  return (
      <div className="app">
      <Switch>
          <Route exact path="/" component={LandingES} />
          <Route path="/fr" component={LandingFR} />
          <Route path="/calculo" component={Form} />
        </Switch>
      </div>
  );
}

export default App;


