import React from 'react';
import '../styles/App.css';
import '../styles/Modal.css';


class PrivacyFR extends React.Component {

  render() {
    return (
      <React.Fragment>   
        <h3 className="privacy-text-title">DESCRIPTION DU RESPONSABLE DU TRAITEMENT DES DONNÉES</h3>
        
        <p className="privacy-text">Nom: <strong>TKNIKA</strong></p>

        <p className="privacy-text">Adresse: Zamalbide auzoa z/g, Barrio Zamalbide s/n, C.P. 20100, Errenteria (Gipuzkoa)</p>

        <p className="privacy-text">Courrier électronique: <a href="mailto:info@tknika.com">info@tknika.com</a></p>

        <ol className="privacy-list">
          <li className="privacy-list-items"><strong>Formulaire de contact sur Internet.</strong>
            <ul className="unorder-list">
              <li className="unorder-items" type="disc"><u>Objectif</u>. Répondre à votre demande d'information, faite par le biais de notre formulaire de contact.</li>
              <li className="unorder-items" type="disc"><u>Légitimation</u>. La légitimation du traitement de vos données est le consentement explicite de la partie intéressée.</li>
              <li className="unorder-items" type="disc"><u>Bénéficiaires</u>. Aucune donnée ne sera transférée à des tiers, sauf si la loi l'exige.</li>                  
            </ul>
          </li>

          <li className="privacy-list-items"><strong>Exclusivement pour les contacts professionnels.</strong>
            <ul className="unorder-list">
              <li className="unorder-items" type="disc"><u>Objectif</u>. Au <strong>TKNIKA</strong> aux utilisateurs qui s'y abonnent.</li>
              <li className="unorder-items" type="disc"><u>Légitimation</u>. La légitimité du traitement de vos données est un intérêt légitime.</li>
              <li className="unorder-items" type="disc"><u>Bénéficiaires</u>. Aucune donnée ne sera transférée à des tiers, sauf si la loi l'exige.</li>                  
            </ul>
          </li>

          <li className="privacy-list-items"><strong>Envoi de la newsletter</strong>
            <ul className="unorder-list">
              <li className="unorder-items" type="disc"><u>Objectif</u>. Gérer l'envoi de la newsletter du <strong>TKNIKA</strong> aux utilisateurs qui s'y abonnent.</li>
              <li className="unorder-items" type="disc"><u>Légitimation</u>. La légitimation du traitement des données à caractère personnel est le consentement exprès des intéressés qui souscrivent à ce service en cochant la case prévue à cet effet.</li>
              <li className="unorder-items" type="disc"><u>Bénéficiaires</u>.Aucune donnée ne sera transférée à des tiers, à moins que la loi ne l'y oblige.</li>                  
            </ul>
          </li>

          <li className="privacy-list-items"><strong>Candidatures spontanées.</strong>
            <ul className="unorder-list">
              <li className="unorder-items" type="disc"><u>Objectif</u>. Pour vous impliquer dans les processus de sélection du personnel effectués par le <strong>TKNIKA</strong>.</li>
              <li className="unorder-items" type="disc"><u>Légitimation</u>. La légitimité du traitement des données à caractère personnel repose sur le consentement explicite donné par l'intéressé en vertu de sa candidature spontanée.</li>
              <li className="unorder-items" type="disc"><u>Bénéficiaires</u>.Aucune donnée ne sera transférée à des tiers, à moins que la loi ne l'y oblige.</li>                  
            </ul>
          </li>

          <li className="privacy-list-items"><strong>Exclusivement pour les clients.</strong>
            <ul className="unorder-list">
              <li className="unorder-items" type="disc"><u>Objectif</u>.
                <ul className="unorder-list">
                  <li className="unorder-items-2">Au <strong>TKNIKA</strong> nous traitons vos données personnelles dans le but de gérer les services administratifs et logistiques découlant de la relation contractuelle ainsi que les engagements découlant du contrat.</li>
                  <li className="unorder-items-2">Si vous nous avez donné votre consentement, le <strong>TKNIKA</strong> traitera vos données dans le but de vous offrir nos produits et services, ainsi que des informations sur les conférences, les entretiens et les formations du secteur.</li>
                </ul>
              </li>
              <li className="unorder-items" type="disc"><u>Légitimation</u>. La légitimation du traitement de vos données est le consentement explicite de l'intéressé, l'exécution d'une obligation légale et l'exécution d'un contrat.</li>
              <li className="unorder-items" type="disc"><u>Bénéficiaires</u>. Aucune donnée ne sera transférée à des tiers, à moins que la loi ne l'y oblige.</li>                  
            </ul>
          </li>
        </ol>

        <h3 className="privacy-text-title">LES DROITS EN MATIÈRE DE TRAITEMENT DES DONNÉES</h3>
        <p className="privacy-text">Pour tous les traitements de données présents dans ce système de confidentialité, vous pouvez exercer vos droits d'accès, de rectification, de suppression, d'opposition, ainsi que d'autres droits précisés dans les informations complémentaires.</p>

        <h3 className="privacy-text-title">INFORMATIONS COMPLÉMENTAIRES SUR LA PROTECTION DES DONNÉES</h3>
        <p className="privacy-text">Les données que le <strong>TKNIKA</strong> peut recueillir par l'utilisation, la navigation sur la page Web, par nos formulaires, par e-mail ou en raison de la relation contractuelle que vous avez avec le <strong>TKNIKA</strong> sont des données personnelles. En remplissant chacun des formulaires de notre page web, il y aura une série de données obligatoires, dont les champs sont marqués avec le symbole *. Si ces champs ne sont pas remplis, il ne sera pas possible d’atteindre les objectifs décrits dans les formulaires.  Dans certains cas, nous vous informons qu'en utilisant et en parcourant simplement notre page Web, le <strong>TKNIKA</strong> stockera les données relatives à:</p>
          <ul className="unorder-list">
            <li>L’adresse IP</li>
            <li>La version du navigateur</li>
            <li>Le système d'exploitation</li>
            <li>La durée de la visite ou de la navigation sur le site</li>
          </ul>
          <p className="privacy-text">Le <strong>TKNIKA</strong> se réserve le droit de modifier ou d'adapter cette politique de confidentialité à tout moment. Nous vous recommandons donc de la consulter à chaque fois que vous accédez au site web.</p>
          <h4 className="privacy-text-title">Dans quel but traitons-nous vos données personnelles?</h4>
          <p className="privacy-text">La finalité du traitement des données recueillies par le biais des formulaires disponibles sur notre page Web, par courrier électronique ou en vertu de la relation contractuelle qui vous lie au <strong>TKNIKA</strong>, sera celle décrite, dans chaque cas, dans les informations de base sur la protection des données.</p>
          <h4 className="privacy-text-title">Combien de temps conserverons-nous vos données personnelles?</h4>
          <p className="privacy-text">Au <strong>TKNIKA</strong> nous conserverons vos données personnelles pendant les périodes établies dans le cadre ci-dessous:</p>                 
          <ul className="unorder-list">
            <li><strong>Formulaire de contact sur le Web:</strong> vos données personnelles seront traitées pendant le temps strictement nécessaire au traitement de votre demande.</li>
            <li><strong>Envoi de la lettre d'information:</strong> vos données personnelles seront conservées jusqu'à ce que vous annuliez votre abonnement à la newsletter du <strong>TKNIKA</strong>.</li>
            <li><strong>Candidature spontanée:</strong> Conformément à la politique de conservation du <strong>TKNIKA</strong>, vos données personnelles seront conservées pendant une période maximale <strong>d'UN AN</strong> à compter de la réception de votre CV.</li>
            <li><strong>Exclusivement pour les contacts professionnels:</strong> Jusqu'à la fin de nos relations professionnelles ou jusqu'à ce que vous décidiez d'annuler ou de vous opposer au traitement de vos coordonnées.</li>
            <li><strong>Exclusif pour les clients:</strong> Vos données personnelles seront traitées jusqu'à la fin de la relation contractuelle. Une fois la relation contractuelle terminée et à des fins commerciales, vos données seront conservées pendant une période de 6 ans conformément aux dispositions du code du commerce. À des fins fiscales, vos données seront conservées pendant une période de 4 ans conformément à la loi fiscale générale.</li>
          </ul>
          <p className="privacy-text">Vos données personnelles utilisées pour les expéditions concernant nos produits et services, ainsi que les informations sur les conférences, les cours et les formations dans le secteur, seront conservées jusqu'à ce que vous révoquiez le consentement donné à cette fin.</p>
        <h4 className="privacy-text-title">Quelle est la légitimité du traitement de vos données?</h4>
        <p className="privacy-text">La légitimité du traitement des données contenues dans cette politique de confidentialité est:</p>
        <ul className="unorder-list">
          <li><strong>Exécution d'un contrat de prestation de services.</strong></li>
          <li><strong>Consentement explicite de la personne concernée au traitement des données suivantes:</strong> 
            <ul className="unorder-list">
              <li>Traitement des données dans les formulaires de contact sur le web.</li>
              <li>Traitement des données fournies lors de l'abonnement à la Newsletter du <strong>TKNIKA</strong>.</li>
              <li>Traitement des données fournies en vertu de votre candidature spontanée.</li>
              <li>Traitement des données relatives aux clients figurant sur les factures et les contrats.</li>
              <li>L'envoi d'informations sur nos produits et services, ainsi que d'informations sur les conférences, entretiens et formations dans le secteur, sera conservé jusqu'à ce que vous révoquiez le consentement donné à cet effet.</li>
            </ul>
          </li>
          <li><strong>Respect d'une obligation légale</strong>:
            <ul className="unorder-list"> 
              <li>Les périodes de conservation de vos données une fois la relation contractuelle terminée, sont basées sur le respect des dispositions du Code de commerce.</li>
              <li>La communication de vos données à la demande de l'Inspection fiscale est couverte par la loi 58/2003 du 17 décembre, Fiscalité générale.</li>
            </ul>
          </li>
          <li><strong><u>Intérêt légitime poursuivi par le responsable du traitement des données.</u></strong> 
            <ul className="unorder-list">
              <li>Le traitement des données de contact professionnelles.</li>
            </ul>
          </li>
        </ul>

        <h4 className="privacy-text-title">À quels destinataires vos données seront-elles communiquées?</h4>
        <p className="privacy-text">Comme indiqué dans les informations de base, aucune donnée ne sera transférée à des tiers, sauf si la loi l'y oblige. Le cas échéant, les données relatives aux clients peuvent être transférées aux organismes suivants, conformément à une obligation légale:</p>
        <ul className="unorder-list">
          <li>Les données nécessaires à l'exécution de l'obligation légale peuvent être transférées à l'Agence fiscale espagnole.</li>
          <li>Aux établissements bancaires correspondants, afin d'être à jour dans les paiements.</li>
          <li>En cas de demande, vos données seront transférées à l'inspection du travail.</li>
        </ul>

        <h4 className="privacy-text-title">Quels sont vos droits par rapport au traitement des données?</h4>
        <p className="privacy-text">En tant que propriétaire des données, vous avez le droit d'obtenir la confirmation de l'existence d'un traitement de vos données, d'accéder à vos données personnelles, de demander la rectification des données inexactes ou, le cas échéant, de demander leur suppression, lorsque, entre autres, les données ne sont plus nécessaires aux fins pour lesquelles elles ont été collectées ou que vous, en tant que personne concernée, retirez le consentement donné. Le <strong>TKNIKA</strong> traitera et conservera vos données conformément à la législation en vigueur, sans préjudice du fait qu'en tant que personne concernée, vous pouvez demander que vos données soient traitées dans tous les cas. Dans certains cas, vous pouvez exercer votre droit de faire transférer vos données dans un format structuré, pour un usage commun ou une lecture automatique, à vous ou au nouveau responsable du traitement que vous désignez.  Vous aurez le droit de révoquer à tout moment votre consentement à l'un des traitements pour lesquels vous l'avez donné. Vous aurez le droit de déposer une plainte auprès de l'Agence espagnole de protection des données si vous estimez que vos droits n'ont pas été correctement exercés. Le délai maximum de résolution sera d'un mois à compter de la réception de votre demande.  En cas de modification de vos données, veuillez nous en informer par écrit afin de les mettre à jour.</p>

        <p className="privacy-text">© Copyright 2020 TKNIKA - Tous droits réservés.</p>
    </React.Fragment>
    );
  }
}

export default PrivacyFR;