import React from 'react';
import '../styles/App.css';
import '../styles/PopUp.css';
import mapa from '../media/mapa_vel_viento.png';

class PopUp extends React.Component {
  render() {
    return (
      <div className="popup">
        <div className="popup-inner">
          <div className="popup-title-box">
            <p className="popup-title">Mapa de velocidad básica del viento</p>
            <button className="popup-btn" onClick={this.props.closePopup}>X</button>
          </div>
          <img src={mapa} alt="Mapa de velocidad básica del viento" className="popup-img" />
        </div>
      </div>
    );
  }
}

export default PopUp;